import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import changeHandler from 'common/form/changeHandler';
import submitHandler from 'common/form/submitHandler';
import {
  FormLayout, TextField, Form, Button, Card, TextContainer, Banner,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { setFormData, submit } from './reg-page.slice';
import './reg-page.scss';

const Reg = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    formControls, isLoading, isSuccessful, isFormValid,
  } = useSelector((state) => state.regPageReducer);
  const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async (formData) => {
    await dispatch(submit(formData));
  };

  if (isSuccessful) {
    return (
      <div className="reg-page">
        <Card title={t('Sign up')} sectioned>
          <TextContainer>
            <p>
              {t('user_created_successfully')}
            </p>
            <Banner>
              {t('Click here')}
              {' '}
              <Link to="/auth/login">{t('Log In')}</Link>
            </Banner>
          </TextContainer>
        </Card>
      </div>
    );
  }
  return (
    <div className="reg-page">
      <Card title={t('Sign up')} sectioned>
        <Form className="reg-page_form" onSubmit={submitHandler(formControls, handleFormSubmit)}>
          <FormLayout>
            <TextField label={t('Email')} autoComplete="off" type="email" id="email" value={formControls.email.value} onChange={changeHandler(formControls, handleFormChange)} />
            <TextField label={t('Password')} autoComplete="off" type="password" id="password" value={formControls.password.value} onChange={changeHandler(formControls, handleFormChange)} />
            <TextField label={t('Name')} type="text" id="name" value={formControls.name.value} onChange={changeHandler(formControls, handleFormChange)} />
            <TextField label={t('Phone')} type="text" id="phone" value={formControls.phone.value} onChange={changeHandler(formControls, handleFormChange)} />
            <Button loading={isLoading} disabled={!isFormValid} submit>{t('Submit')}</Button>
          </FormLayout>
        </Form>
      </Card>
    </div>
  );
};

export default Reg;
