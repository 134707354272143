import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  isOpen: false,
  size: 'large',
  closeOnOverlayClick: null,
  component: null,
  title: null,
};

const popupSlice = createSlice({
  name: 'modalxSlice',
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setModalxOptions(state, action) {
      state.size = action.payload.size;
      state.title = action.payload.title;
    },
    setIsModalxOpen(state, action) {
      state.isOpen = action.payload;
      state.loading = false;
    },
    setDestroy() {
      return initialState;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setModalxComponent(state, action) {
      state.component = action.payload;
      state.isOpen = true;
    },
    setModalxStyle(state, action) {
      state.size = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = popupSlice;

// Extract and export each action creator by name
export const {
  set,
  setDestroy,
  setIsModalxOpen,
  setLoading,
  setModalxComponent,
  setModalxStyle,
  setModalxOptions,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
