import axios from 'axios';
import { API_URL } from '../../constants';

export const fetch = () => {
  const url = `${API_URL}/companies/fetch`;
  return axios.get(url).then((response) => response.data);
};

// eslint-disable-next-line import/prefer-default-export
export const select = ({ companyToSelect }) => {
  const url = `${API_URL}/companies/select`;
  return axios.post(url, { companyToSelect }).then((response) => response.data);
};

export const create = (data) => {
  const url = `${API_URL}/companies/create`;
  return axios.post(url, data).then((response) => response.data);
};
