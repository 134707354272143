import { createSlice } from '@reduxjs/toolkit';
import { fetchById, resolveById } from './task.service';
import { fetchTasks } from '../tasks/tasks.slice';

const TaskSlice = createSlice({
  name: 'task',
  initialState: {
    taskEntity: null,
    isLoading: false,
    isResolveLoading: false,
    isError: false,
    errMsg: null,
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setResolveLoading(state) {
      state.isResolveLoading = true;
    },
    setIsResolved(state, action) {
      state.taskEntity.isResolved = action.payload.isResolved;
      state.isResolveLoading = false;
    },
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setTaskEntity(state, action) {
      state.isLoading = false;
      state.taskEntity = action.payload.taskEntity;
      state.isResolveLoading = false;
    },
    setError(state, action) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = TaskSlice;

// Extract and export each action creator by name
export const {
  setLoading, setTaskEntity, setError, setResolveLoading, setIsResolved,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const resolveTaskById = (taskId) => async (dispatch) => {
  dispatch(setResolveLoading());
  try {
    const { isResolved } = await resolveById(taskId);
    dispatch(setIsResolved({ isResolved }));
    dispatch(fetchTasks());
  } catch (e) {
    dispatch(setError(''));
  }
};

export const fetchTaskById = (taskId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { taskEntity } = await fetchById(taskId);
    dispatch(setTaskEntity({ taskEntity }));
  } catch (e) {
    dispatch(setError(''));
  }
};
