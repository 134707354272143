import axios from 'axios';
import { API_URL } from '../../constants';

export const logOut = () => {
  const url = `${API_URL}/logout`;
  return axios.get(url).then((response) => response.data);
};

export const authSession = () => {
  const url = `${API_URL}/session`;
  return axios.get(url).then((response) => response.data);
};

// eslint-disable-next-line import/prefer-default-export
export const logIn = ({ email, password }) => {
  const url = `${API_URL}/login`;
  return axios.post(url, { email, password }).then((response) => response.data);
};

export const signUp = (data) => {
  const url = `${API_URL}/signup`;
  return axios.post(url, data).then((response) => response.data);
};
