import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { create } from '../tasks/tasks.service';
import { fetchTasks } from '../tasks/tasks.slice';

const initialState = {
  isFormValid: false,
  formControls: {
    title: {
      value: '',
      isValid: false,
      validationRules: {
        isRequired: true,
      },
      isTouched: false,
    },
    description: {
      value: '',
      isValid: false,
      validationRules: {
        isRequired: false,
      },
      isTouched: false,
    },
    assignedTo: {
      value: '',
      isValid: false,
      validationRules: {
        isRequired: true,
      },
      isTouched: false,
    },
    dueDate: {
      value: '',
      isValid: false,
      validationRules: {
        isRequired: true,
      },
      isTouched: false,
    },
  },
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
};
const LoginSlice = createSlice({
  name: 'taskCreate',
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setSuccessfullySaved(state) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.isSuccessfullySaved = true;
    },
    setFormData(state, action) {
      state.formControls = action.payload.validatedFormControls;
      state.isFormValid = action.payload.isFormValid;
    },
    destroy(state) {
      _.assign(state, initialState);
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = LoginSlice;

// Extract and export each action creator by name
export const {
  setLoading, setError, setSuccessfullySaved, setFormData, destroy,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = ({
  title, description, assignedTo, dueDate,
}) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await create({
      title, description, assignedTo, dueDate,
    });
    dispatch(setSuccessfullySaved());
    dispatch(fetchTasks());
    return true;
  } catch (e) {
    dispatch(setError('400'));
    return false;
  }
};
