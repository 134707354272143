import { createSlice } from '@reduxjs/toolkit';
import { fetch } from './tasks.service';

const TasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasksCollection: [],
    isLoading: false,
    isError: false,
    errMsg: null,
    queryValue: null,
    filtersValue: {},
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setTasks(state, action) {
      state.isLoading = false;
      state.tasksCollection = action.payload.tasksCollection;
    },
    setError(state, action) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
    setFilterValue(state, action) {
      const { key, value } = action.payload;
      state.filtersValue[key] = value;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = TasksSlice;

// Extract and export each action creator by name
export const {
  setLoading, setTasks, setError, setFilterValue,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const fetchTasks = (filtersValue) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { tasksCollection } = await fetch(filtersValue);
    dispatch(setTasks({ tasksCollection }));
  } catch (e) {
    dispatch(setError(''));
  }
};
