import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card, Button, IndexTable, TextStyle,
} from '@shopify/polaris';
import { fetchAccounts } from './accounts.slice';
import { setModalxComponent } from '../modalx/modalx.slice';

const Accounts = () => {
  const dispatch = useDispatch();
  const resourceName = {
    singular: 'account',
    plural: 'accounts',
  };

  const {
    accountsCollection, isLoading,
  } = useSelector((state) => state.accountsReducer);

  const handleCreateAccount = () => {
    dispatch(setModalxComponent('AccountCreate'));
  };

  const createButton = (<Button onClick={handleCreateAccount} size="slim" primary>Create account</Button>);

  useEffect(() => {
    dispatch(fetchAccounts());
  }, []);

  const rowMarkup = accountsCollection.map(
    ({
      id, name, address, phone, email,
    }, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        position={index}
      >
        <IndexTable.Cell>
          <TextStyle variation="strong">{name}</TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>{address}</IndexTable.Cell>
        <IndexTable.Cell>{phone}</IndexTable.Cell>
        <IndexTable.Cell>{email}</IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <div style={{ padding: '16px' }} className="accounts">
      <Card>
        <div>
          <div>
            {createButton}
          </div>
        </div>
        <IndexTable
          resourceName={resourceName}
          itemCount={accountsCollection.length}
          loading={isLoading}
          headings={[
            { title: 'Account' },
            { title: 'Address' },
            { title: 'Phone' },
            { title: 'Email' },
          ]}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
    </div>
  );
};

export default Accounts;
