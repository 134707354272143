import { createSlice } from '@reduxjs/toolkit';
import { create } from '../accounts/accounts.service';
import { fetchAccounts } from '../accounts/accounts.slice';

const LoginSlice = createSlice({
  name: 'accountCreate',
  initialState: {
    isFormValid: false,
    formControls: {
      name: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: true,
        },
        isTouched: false,
      },
      code: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: true,
        },
        isTouched: false,
      },
      vat: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: false,
        },
        isTouched: false,
      },
      address: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: true,
        },
        isTouched: false,
      },
      description: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: false,
        },
        isTouched: false,
      },
      phone: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: false,
        },
        isTouched: false,
      },
      email: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: false,
        },
        isTouched: false,
      },
    },
    isLoading: false,
    isError: false,
    isSuccessfullySaved: false,
    errMsg: null,
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setSuccessfullySaved(state) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.isSuccessfullySaved = true;
    },
    setFormData(state, action) {
      state.formControls = action.payload.validatedFormControls;
      state.isFormValid = action.payload.isFormValid;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = LoginSlice;

// Extract and export each action creator by name
export const {
  setLoading, setError, setSuccessfullySaved, setFormData,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = ({
  name, code, vat, address, description, phone, email,
}) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await create({
      name, code, vat, address, description, phone, email,
    });
    dispatch(setSuccessfullySaved());
    dispatch(fetchAccounts());
    return true;
  } catch (e) {
    dispatch(setError('400'));
    return false;
  }
};
