import axios from 'axios';
import { API_URL } from '../constants';

export const fetch = () => {
  const url = `${API_URL}/users/fetchByCompany`;
  return axios.get(url).then((response) => response.data);
};

// eslint-disable-next-line import/prefer-default-export
export const inviteToCompany = ({ userToInvite }) => {
  const url = `${API_URL}/users/inviteToCompany`;
  return axios.post(url, { userToInvite }).then((response) => response.data);
};
