import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch, Route, useRouteMatch, useHistory,
} from 'react-router-dom';
import {
  Frame,
  TopBar,
  Navigation,
  Loading,
} from '@shopify/polaris';
import {
  // HomeMajor,
  // CustomersMajor,
  ExitMajor,
  TickMinor,
} from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import CompanySelect from '../../features/company-select/company-select';
import {
  checkSession, logout, toogleUserMenu, toogleNav,
} from '../../features/auth/authSlice';
import Modalx from '../../features/modalx/modalx';
import Accounts from '../../features/accounts/accounts';
import Tasks from '../../features/tasks/tasks';

const AppLayout = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    session,
    isLoading,
    userMenuActive,
    isError,
    navActive,
  } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (isError) {
      window.asist = { redirect: window.location.pathname };
      history.push('/auth/login');
    } else if (!session && !isLoading) {
      dispatch(checkSession());
    }
  }, [isError, session, isLoading, isError]);

  if (!session) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }

  const handleLogOut = async () => {
    await dispatch(logout());
  };

  const handleToogleUserMenu = async () => {
    await dispatch(toogleUserMenu());
  };

  const handleNavigationToggle = async () => {
    await dispatch(toogleNav());
  };

  const userMenuActions = [
    {
      items: [{ content: t('log_out'), icon: ExitMajor, onAction: handleLogOut }],
    },
  ];
  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={session.email}
      open={userMenuActive}
      onToggle={handleToogleUserMenu}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      secondaryMenu={<CompanySelect />}
      userMenu={userMenuMarkup}
      onNavigationToggle={handleNavigationToggle}
    />
  );

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          // {
          //   url: '/app',
          //   label: t('Dashboard'),
          //   icon: HomeMajor,
          // },
          // {
          //   url: '/app/accounts',
          //   label: t('Accounts'),
          //   icon: CustomersMajor,
          // },
          {
            url: '/app/tasks',
            label: t('tasks'),
            icon: TickMinor,
          },
        ]}
      />
    </Navigation>
  );
  return (
    <Frame
      topBar={topBarMarkup}
      navigation={navigationMarkup}
      showMobileNavigation={navActive}
      onNavigationDismiss={handleNavigationToggle}
    >
      <Switch>
        <Route exact path={`${path}/`}>
          <Tasks />
        </Route>
        <Route path={`${path}/accounts`}>
          <Accounts />
        </Route>
        <Route path={`${path}/tasks`}>
          <Tasks />
        </Route>
      </Switch>
      <Modalx />
    </Frame>
  );
};

export default AppLayout;
