import { createSlice } from '@reduxjs/toolkit';
import { logIn } from '../auth/auth.service';
import { checkSession } from '../auth/authSlice';

const LoginSlice = createSlice({
  name: 'login',
  initialState: {
    isFormValid: false,
    formControls: {
      email: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: true,
        },
        isTouched: false,
      },
      password: {
        value: '',
        isValid: false,
        validationRules: {
          isRequired: true,
        },
        isTouched: false,
      },
    },
    isLoading: false,
    isError: false,
    errMsg: null,
    isLoggedIn: false,
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setLoggedIn(state) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.isLoggedIn = true;
    },
    setFormData(state, action) {
      state.formControls = action.payload.validatedFormControls;
      state.isFormValid = action.payload.isFormValid;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = LoginSlice;

// Extract and export each action creator by name
export const {
  setLoading, setError, setLoggedIn, setFormData,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const login = ({ email, password }) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { user } = await logIn({ email, password });
    if (user) {
      dispatch(setLoggedIn());
      dispatch(checkSession());
    }

    return true;
  } catch (e) {
    dispatch(setError('400'));
    return false;
  }
};
