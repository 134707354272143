import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import changeHandler from 'common/form/changeHandler';
import submitHandler from 'common/form/submitHandler';
import {
  FormLayout, TextField, Form, Button,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import DatePickerx from 'features/datepicker/date-picker';
import { setIsModalxOpen } from 'features/modalx/modalx.slice';
import UserSelect from '../user-select/user-select';
import { setFormData, submit, destroy } from './task-create.slice';

const TaskCreate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    formControls, isLoading, isFormValid, isSuccessfullySaved,
  } = useSelector((state) => state.taskCreateReducer);
  const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async (formData) => {
    await dispatch(submit(formData));
  };
  if (isSuccessfullySaved) {
    dispatch(destroy());
    dispatch(setIsModalxOpen(false));
  }
  const d = formControls.dueDate ? formControls.dueDate.value.split(/[- :]/) : null;
  const dueDate = d ? new Date(Date.UTC(d[0], d[1] - 1, d[2], d[3], d[4], d[5])) : null;

  return (
    <div className="task-create">
      <Form className="task-create_form" onSubmit={submitHandler(formControls, handleFormSubmit)}>
        <FormLayout>
          <TextField label={t('title')} type="text" id="title" autoComplete="off" value={formControls.title.value} onChange={changeHandler(formControls, handleFormChange)} />
          <UserSelect label={t('assigned')} name="assignedTo" onChange={changeHandler(formControls, handleFormChange)} />
          <div style={{ display: 'flex' }}>
            <div>
              <div>{t('due_date')}</div>
              <DatePickerx
                name="dueDate"
                onChange={changeHandler(formControls, handleFormChange)}
                selected={dueDate}
              />
            </div>
            <div style={{ flex: '1' }} />
          </div>
          <div>
            <TextField label={t('description')} type="text" id="description" autoComplete="off" multiline={10} value={formControls.description.value} onChange={changeHandler(formControls, handleFormChange)} />
          </div>
          <Button loading={isLoading} disabled={!isFormValid} submit>{t('create_task')}</Button>
        </FormLayout>
      </Form>
    </div>
  );
};

export default TaskCreate;
