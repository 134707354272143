import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page, IndexTable, TextStyle, Layout, Icon, Modal, Card, Filters, ChoiceList,
} from '@shopify/polaris';
import {
  TickMinor,
  ClockMajor,
} from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import {
  Switch, Route, useRouteMatch, Link, useHistory,
} from 'react-router-dom';
import Task from 'features/task/task';
import UserSelect from 'features/user-select/user-select';
import { fetchTasks, setFilterValue } from './tasks.slice';
import { setModalxComponent } from '../modalx/modalx.slice';

const Tasks = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const resourceName = {
    singular: 'task',
    plural: 'tasks',
  };

  const {
    tasksReducer: { tasksCollection, isLoading, filtersValue },
    taskReducer: { taskEntity },
  } = useSelector((state) => state);

  const handleCreateTask = () => {
    dispatch(setModalxComponent('TaskCreate'));
  };

  const handleTaskClose = () => {
    history.push('/app');
  };
  const disambiguateLabel = (key, value) => {
    switch (key) {
      case 'isResolved':
        if (value.length > 1) {
          return `${t('resolution')} ${t(value[0])}, ${t(value[1])}`;
        }
        return `${t('resolution')} ${t(value[0])}`;
      case 'textSearch':
        return `${t('search')}: ${t(value)}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    dispatch(fetchTasks(filtersValue));
  }, [filtersValue]);

  const rowMarkup = tasksCollection.map(
    ({
      id, title, assignedTo, dueDate, isResolved, diffInDays, owner,
    }, index) => {
      const daysLeft = diffInDays ? ` (${t('left')} ${diffInDays} d.)` : '';
      return (
        <IndexTable.Row
          id={id}
          key={id}
          position={index}
          selected={_.get(taskEntity, 'id') === id}
          status={isResolved ? 'success' : ''}
        >
          <div style={{ padding: '1.2rem 1.6rem' }}>
            <div>
              <div style={{ display: 'flex' }}>
                <div>
                  {isResolved ? <Icon source={TickMinor} color="success" /> : <Icon source={ClockMajor} color="subdued" />}
                </div>
                <div style={{ paddingLeft: '10px', flex: '1' }}>
                  <Link to={`/app/tasks/${id}`}>
                    <TextStyle variation="strong">{title}</TextStyle>
                  </Link>
                </div>
                <div style={{ whiteSpace: 'nowrap' }}>{owner}</div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
              <TextStyle variation={!isResolved && diffInDays < 0 ? 'negative' : ''}>
                {dueDate}
                {daysLeft}
              </TextStyle>
              <div>{assignedTo}</div>
            </div>
          </div>
        </IndexTable.Row>
      );
    },
  );
  const { path } = useRouteMatch();
  const handleFilterChange = (key, value) => {
    dispatch(setFilterValue({ key, value }));
  };
  const handleFilterResolutionChange = (value) => {
    dispatch(setFilterValue({ key: 'isResolved', value }));
  };
  const handleFilterOwnerChange = (value) => {
    dispatch(setFilterValue({ key: 'owner', value }));
  };
  const handleFilterAssignedToChange = (value) => {
    dispatch(setFilterValue({ key: 'assignedTo', value }));
  };
  const handleQueryChange = (value) => {
    dispatch(setFilterValue({ key: 'textSearch', value }));
  };
  const handleQueryValueRemove = () => {
    dispatch(setFilterValue({ key: 'textSearch', value: '' }));
  };
  const handleClearAll = () => {

  };
  const appliedFilters = [];
  Object.keys(filtersValue).forEach((key) => {
    if (filtersValue[key] && filtersValue[key].length > 0) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, filtersValue[key]),
        onRemove: () => handleFilterChange(key, null),
      });
    }
  });

  const filters = [
    {
      key: 'isResolved',
      label: t('resolution'),
      filter: (
        <ChoiceList
          allowMultiple
          title={t('resolution')}
          choices={[
            {
              label: t('is_resolved'),
              value: 'is_resolved',
            },
            {
              label: t('not_resolved'),
              value: 'not_resolved',
            },
          ]}
          selected={filtersValue.isResolved || []}
          onChange={handleFilterResolutionChange}
        />
      ),
      shortcut: true,
    },
    {
      key: 'owner',
      label: t('owner'),
      filter: (
        <UserSelect name="owner" value={filtersValue.owner} onChange={handleFilterOwnerChange} />
      ),
      shortcut: true,
    },
    {
      key: 'assignedTo',
      label: t('assignedTo'),
      filter: (
        <UserSelect name="assignedTo" value={filtersValue.assignedTo} onChange={handleFilterAssignedToChange} />
      ),
      shortcut: true,
    },
  ];

  return (
    <Layout>
      <Layout.Section>
        <Page
          fullWidth
          primaryAction={{ content: t('create_task'), onAction: handleCreateTask }}
          title={t('tasks')}
        >
          <Card>
            <div style={{ padding: '16px', display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Filters
                  queryValue={filtersValue.textSearch}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={handleQueryChange}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleClearAll}
                />
              </div>
            </div>
            <IndexTable
              resourceName={resourceName}
              itemCount={tasksCollection.length}
              loading={isLoading}
              condensed
              headings={[
                { title: t('title') },
                { title: t('reporter') },
                { title: t('assigned') },
                { title: t('resolution') },
                { title: t('due_date') },
              ]}
              selectable={false}
            >
              {rowMarkup}
            </IndexTable>
          </Card>
        </Page>
      </Layout.Section>
      <Switch>
        <Route path={`${path}/:taskId`}>
          <Modal
            open
            titleHidden
            onClose={handleTaskClose}
          >
            <Modal.Section>
              <Task />
            </Modal.Section>
          </Modal>
        </Route>
      </Switch>
    </Layout>
  );
};

export default Tasks;
