import axios from 'axios';
import { API_URL } from '../../constants';

export const fetch = () => {
  const url = `${API_URL}/accounts/fetch`;
  return axios.get(url).then((response) => response.data);
};

export const create = (data) => {
  const url = `${API_URL}/accounts/create`;
  return axios.post(url, data).then((response) => response.data);
};
