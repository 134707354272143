import axios from 'axios';
import { API_URL } from '../../constants';

export const fetchById = (taskId) => {
  const url = `${API_URL}/tasks/fetchById/${taskId}`;
  return axios.get(url).then((response) => response.data);
};

export const resolveById = (taskId) => {
  const url = `${API_URL}/tasks/resolveById/${taskId}`;
  return axios.get(url).then((response) => response.data);
};
