import { createSlice } from '@reduxjs/toolkit';
import { authSession, logOut } from './auth.service';

const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    isError: false,
    errorMessage: null,
    session: null,
    userMenuActive: false,
    navActive: false,
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setSession(state, action) {
      state.session = action.payload.session;
      state.isLoggedIn = true;
      state.isLoading = false;
    },
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setError(state, action) {
      state.isLoading = false;
      state.session = null;
      state.isError = true;
      state.isLoggedIn = false;
      state.erorrMessage = action.payload.error;
    },
    setLogout(state) {
      state.isLoading = false;
      state.session = null;
      state.isLoggedIn = false;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setUserMenuActive(state) {
      state.userMenuActive = !state.userMenuActive;
    },
    setNavActive(state) {
      state.navActive = !state.navActive;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = AuthSlice;

// Extract and export each action creator by name
export const {
  setSession, setLoading, setError, setLogout, setUserMenuActive, setNavActive,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;

export const toogleUserMenu = () => async (dispatch) => {
  dispatch(setUserMenuActive());
};

export const toogleNav = () => async (dispatch) => {
  dispatch(setNavActive());
};

export const checkSession = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const session = await authSession();
    dispatch(setSession({ session }));
    return true;
  } catch (e) {
    dispatch(setError('403'));
    return false;
  }
};

export const logout = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    await logOut();
    dispatch(setLogout());
  } catch (e) {
    dispatch(setError(e));
  }
};
