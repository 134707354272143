import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select, Button, Spinner,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { fetchCompanies, selectCompany } from './company-select.slice';
import { setModalxComponent } from '../modalx/modalx.slice';

const CompanySelect = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    companiesCollection, selectedCompany, isLoading,
  } = useSelector((state) => state.companySelectReducer);

  const handleCreateCompany = () => {
    dispatch(setModalxComponent('CompanyCreate'));
  };

  const handleCompanyChange = (companyToSelect) => {
    dispatch(selectCompany({ companyToSelect }));
  };

  const createButton = (<Button style={{ width: '100%' }} onClick={handleCreateCompany} size="slim" primary>{t('create_company')}</Button>);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  if (isLoading && companiesCollection.length === 0) {
    return (<Spinner accessibilityLabel="" size="small" />);
  }

  if (companiesCollection.length === 0 && !isLoading) {
    return createButton;
  }

  return (
    <div>
      <Select
        label={t('Selected company')}
        labelInline
        options={companiesCollection}
        onChange={handleCompanyChange}
        value={selectedCompany}
        disabled={isLoading}
      />
      {/* {createButton} */}
    </div>
  );
};

export default CompanySelect;
