import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import authReducer from './features/auth/authSlice';
import loginPageReducer from './features/login-page/login-page.slice';
import regPageReducer from './features/reg-page/reg-page.slice';
import companySelectReducer from './features/company-select/company-select.slice';
import companyCreateReducer from './features/company-create/company-create.slice';
import modalxReducer from './features/modalx/modalx.slice';
import accountsReducer from './features/accounts/accounts.slice';
import accountCreateReducer from './features/account-create/account-create.slice';
import tasksReducer from './features/tasks/tasks.slice';
import taskCreateReducer from './features/task-create/task-create.slice';
import taskReducer from './features/task/task.slice';

export default function configureAppStore(preloadedState) {
  const reducer = combineReducers({
    authReducer,
    loginPageReducer,
    regPageReducer,
    companySelectReducer,
    companyCreateReducer,
    modalxReducer,
    accountsReducer,
    accountCreateReducer,
    tasksReducer,
    taskCreateReducer,
    taskReducer,
  });

  const store = configureStore({
    reducer,
    preloadedState,
  });

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('./reducers', () => store.replaceReducer(reducer));
  // }

  return store;
}
