import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import changeHandler from 'common/form/changeHandler';
import submitHandler from 'common/form/submitHandler';
import {
  FormLayout, TextField, Form, Button, Card, TextContainer, Heading,
} from '@shopify/polaris';
import { setFormData, submit } from './account-create.slice';

const AccountCreate = () => {
  const dispatch = useDispatch();
  const {
    formControls, isLoading, isFormValid, isSuccessfullySaved,
  } = useSelector((state) => state.accountCreateReducer);

  const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async (formData) => {
    await dispatch(submit(formData));
  };
  if (isSuccessfullySaved) {
    return (
      <div className="account-create">
        <Card title="Account create" sectioned>
          <TextContainer>
            <Heading>
              Congradulations,
              {formControls.name.value}
              account created successfully
            </Heading>
          </TextContainer>
        </Card>
      </div>
    );
  }

  return (
    <div className="account-create">
      <Form className="account-create_form" onSubmit={submitHandler(formControls, handleFormSubmit)}>
        <FormLayout>
          <TextField label="Name" type="text" id="name" autoComplete="off" value={formControls.name.value} onChange={changeHandler(formControls, handleFormChange)} />
          <TextField label="Code" type="text" id="code" autoComplete="off" value={formControls.code.value} onChange={changeHandler(formControls, handleFormChange)} />
          <TextField label="Vat" type="text" id="vat" autoComplete="off" value={formControls.vat.value} onChange={changeHandler(formControls, handleFormChange)} />
          <TextField label="Address" type="text" id="address" autoComplete="off" value={formControls.address.value} onChange={changeHandler(formControls, handleFormChange)} />
          <TextField label="Phone" type="text" id="phone" autoComplete="off" value={formControls.phone.value} onChange={changeHandler(formControls, handleFormChange)} />
          <TextField label="Email" type="text" id="email" autoComplete="off" value={formControls.email.value} onChange={changeHandler(formControls, handleFormChange)} />
          <TextField label="Description" type="text" id="description" autoComplete="off" multiline={4} value={formControls.description.value} onChange={changeHandler(formControls, handleFormChange)} />
          <Button loading={isLoading} disabled={!isFormValid} submit>Create account</Button>
        </FormLayout>
      </Form>
    </div>
  );
};

export default AccountCreate;
