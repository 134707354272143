import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Page,
  SkeletonPage, Layout, SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Checkbox,
  Spinner,
} from '@shopify/polaris';
import {
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchTaskById, resolveTaskById } from './task.slice';
import './style.scss';

const Task = () => {
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    taskEntity, isLoading, isResolveLoading,
  } = useSelector((state) => state.taskReducer);

  useEffect(() => {
    dispatch(fetchTaskById(taskId));
  }, [taskId]);

  const handleIsResolved = () => {
    dispatch(resolveTaskById(taskId));
  };

  if (isLoading || !taskEntity) {
    return (
      <SkeletonPage primaryAction>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonBodyText />
            </Card>
            <Card sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
            <Card sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );
  }

  const resolveNode = isResolveLoading
    ? (
      <div>
        <Spinner accessibilityLabel="Resolve loading" size="small" />
        <span>{t('resolved')}</span>
      </div>
    )
    : (
      <Checkbox
        label={t('resolved')}
        checked={taskEntity.isResolved}
        onChange={handleIsResolved}
      />
    );

  return (
    <Page
      fullWidth
      title={taskEntity.title}
      primaryAction={resolveNode}
    >
      <TextContainer>
        <div style={{ whiteSpace: 'pre-line' }}>{taskEntity.description}</div>
      </TextContainer>
    </Page>
  );
};

export default Task;
