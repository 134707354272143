import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Frame,
} from '@shopify/polaris';
import AppLayout from './layouts/app/app-layout';
import AuthLayout from './layouts/auth/auth-layout';

function App() {
  return (
    <Frame>
      <Switch>
        <Route path="/auth">
          <AuthLayout />
        </Route>
        <Route path="/app">
          <AppLayout />
        </Route>
        <Redirect to="/auth/login" />
      </Switch>
    </Frame>
  );
}

export default App;
