import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import changeHandler from 'common/form/changeHandler';
import submitHandler from 'common/form/submitHandler';
import {
  FormLayout, TextField, Form, Button, Card,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { setFormData, login } from './login-page.slice';
import './login-page.scss';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    formControls, isLoading, isFormValid,
  } = useSelector((state) => state.loginPageReducer);
  const { session } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (session) {
      const redirectUrl = _.get(window, 'asist.redirect');
      window.asist = null;
      if (redirectUrl) {
        history.push(redirectUrl);
      } else {
        history.push('/app');
      }
    }
  });
  const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async (formData) => {
    await dispatch(login(formData));
  };

  return (
    <div className="login-page">
      <Card title={t('Login to application')} sectioned>
        <Form className="login-page_form" onSubmit={submitHandler(formControls, handleFormSubmit)}>
          <FormLayout>
            <TextField label={t('Email')} type="email" id="email" value={formControls.email.value} onChange={changeHandler(formControls, handleFormChange)} />
            <TextField label={t('Password')} type="password" id="password" value={formControls.password.value} onChange={changeHandler(formControls, handleFormChange)} />
            <Button loading={isLoading} disabled={!isFormValid} submit>{t('Login')}</Button>
          </FormLayout>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
