import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Select,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { fetch } from '../../services/user-select.service';

const UserSelect = ({ value: selectedValue, name, onChange }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(selectedValue);
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (value) => {
    setSelected(value || '');
    onChange(value, name);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data = await fetch();
        const dataCollection = _.map(
          data.usersCollection,
          (row) => ({ label: row.name, value: row.id }),
        );
        dataCollection.unshift({ label: ' - ', value: '' });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  return (
    <div className="user-select">
      <Select
        label={t('select_user')}
        options={collection}
        onChange={handleChange}
        value={selected}
        disabled={isLoading}
      />
    </div>
  );
};

export default UserSelect;

UserSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
};
