import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';
import React from 'react';
import {
  Frame,
} from '@shopify/polaris';
import LoginPage from '../../features/login-page/login-page';
import RegPage from '../../features/reg-page/reg-page';

const AuthLayout = () => {
  const { path } = useRouteMatch();

  return (
    <Frame>
      <Switch>
        <Route path={`${path}/login`}>
          <LoginPage />
        </Route>
        <Route path={`${path}/signup`}>
          <RegPage />
        </Route>
      </Switch>
    </Frame>
  );
};

export default AuthLayout;
