import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      Login: 'Login',
      'Login to application': 'Login to application',
      company_created_successfully: 'Company {{name}} created successfully',
      create_company: 'Create company',
      tasks: 'Tasks',
      create_task: 'Create task',
      due_date: 'Due date',
      select_task_from_list: 'Select a task from the list',
      resolved: 'Resolved',
      is_resolved: 'Resolved',
      not_resolved: 'Not resolved',
      description: 'Description',
      select_user: 'Select user',
      log_out: 'Log out',
      left: 'left',
      search: 'Search',
      owner: 'Owner',
      assignedTo: 'Assigned To',
      user_created_successfully: 'Congratulations, your account has been created. Please use your email and password to access our platform.',

    },
  },
  lt: {
    translation: {
      Login: 'Prisijungti',
      'Login to application': 'Prisijungti prie programos',
      Email: 'El. paštas',
      Password: 'Slaptažodis',
      'Sign up': 'Registracija',
      'Selected company': 'Pasirinkta įmonė',
      create_company: 'Sukurti įmonę',
      company_created_successfully: 'Įmonė sukurta sėkmingai',
      tasks: 'Užduotys',
      create_task: 'Sukurti užduotį',
      title: 'Pavadinimas',
      reporter: 'Sukurė',
      assigned: 'Priskirta',
      resolution: 'Būsena',
      is_resolved: 'Padaryta',
      not_resolved: 'Nepadaryta',
      due_date: 'Iki kada',
      select_task_from_list: 'Pasirinkite užduoti iš sąrašo',
      resolved: 'Padaryta',
      description: 'Aprašymas',
      select_user: 'Pasirinkitę darbuotoją',
      log_out: 'Atsijungti',
      left: 'liko',
      search: 'Paieška',
      owner: 'Sukurė',
      assignedTo: 'Priskirta',
      user_created_successfully: 'Sveikiname, Jūsų paskyrą sukurta sėkmingai. Prisijunkite prie programos su Jūsų el.paštu ir slaptažodžiu',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'lt', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
