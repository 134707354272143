import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import ltTranslations from './polaris-lt.json';
import configureStore from './configureStore';
import './i18n';
import App from './App';
import './index.css';

axios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.withCredentials = true;
    return config;
  }, (error) => Promise.reject(error),
);

// Add a response interceptor
axios.interceptors.response.use((response) => response,
  (error) => {
    if (
      _.get(error, 'response.status') === 403 && _.get(window, 'location.pathname') !== '/login' && _.get(window, 'location.pathname') !== '/signup'
    ) {
      // window.location = '/auth/login';
    }
    return Promise.reject(error);
  });

const store = configureStore();

// eslint-disable-next-line react/prop-types
function AdapterLink({ url, ...rest }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link to={url} {...rest} />;
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <AppProvider i18n={ltTranslations} linkComponent={AdapterLink}>
        <App />
      </AppProvider>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
