/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Stack } from '@shopify/polaris';
import { useSelector, useDispatch } from 'react-redux';
import CompanyCreate from '../company-create/company-create';
import AccountCreate from '../account-create/account-create';
import TaskCreate from '../task-create/task-create';
import {
  setIsModalxOpen,
} from './modalx.slice';
// import './style.scss';

function Modalx() {
  const {
    title,
    isOpen,
    component,
    primaryAction,
    secondaryActions,
    size,
  } = useSelector((state) => state.modalxReducer);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setIsModalxOpen(false));
  };

  const components = {
    CompanyCreate,
    AccountCreate,
    TaskCreate,
  };

  const ComponentNode = components[component];
  return (
    <>
      {ComponentNode ? (
        <>
          <Modal
            title={title || null}
            primaryAction={primaryAction || null}
            secondaryActions={secondaryActions || null}
            open={isOpen}
            onClose={closeModal}
            large={size === 'large'}
            small={size === 'small'}
          >
            <Modal.Section>
              <Stack vertical>
                <ComponentNode />
              </Stack>
            </Modal.Section>
          </Modal>
        </>
      ) : null}
    </>
  );
}

export default Modalx;
