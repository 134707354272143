import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
} from '@shopify/polaris';

const DatePickerx = ({ name, selected, onChange }) => {
  const [{ month, year }, setDate] = useState({
    month: (new Date()).getMonth(),
    year: (new Date()).getFullYear(),
  });

  const handleChange = (value) => {
    onChange(value, name);
  };

  const handleMonthChange = useCallback(
    // eslint-disable-next-line no-shadow
    (month, year) => setDate({ month, year }),
    [],
  );

  return (
    <div className="date-pickerx" style={{ width: '300px' }}>
      <DatePicker
        onChange={({ start }) => { handleChange(start.toISOString().slice(0, 19).replace('T', ' ')); }}
        month={month}
        year={year}
        onMonthChange={handleMonthChange}
        weekStartsOn={1}
        selected={{
          start: selected,
          end: selected,
        }}
      />
    </div>
  );
};

export default DatePickerx;

DatePickerx.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  // disableDatesBefore: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
